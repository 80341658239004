import React, { useEffect } from "react";
import {useState} from 'react'
import styles from '../styles/legalfooter.module.css'

// create a footer component that will automatically change the language of the footer based on the language of the page
export default function LegalFooter ({isEnglish}) {
 
    return (
        <div className={styles.legalFooter}>
            {isEnglish === true ? <p className={styles.footerText}>*CIBC will make a donation directly to Canadian Cancer Society for every card sent up to a maximum of $100,000 
            </p> : <p className={styles.footerText}>*CIBC fera un don directement à la Société canadienne du cancer pour chaque carte envoyée, jusqu'à concurrence de 100 000 $ 
            </p>
            }
            
        </div>
    )
} 