import CIBC from '../assets/EN_Primary-Stacked_Logo_CMYK@2x.png';
import CIBC_FRENCH from '../assets/FR_Primary-Stacked_Logo_CMYK@2x.png'
import Header from './Header';

import styles from '../styles/thankyou.module.css';

const Thankyou = ({isEnglish}) => {
    console.log(isEnglish)
    return (
        <div>
            <Header {...{isEnglish}}/>
            {isEnglish ? <h1>Thank you</h1> : null}
            {!isEnglish ? <h1>Merci</h1> : null}
            {isEnglish ? <h2 className={styles.subheader}>Thank you for joining us in creating a world where no one has to fear a cancer diagnosis. Your card is on its way! </h2> : null}
            {!isEnglish ? <h2 className={styles.subheader}>Merci de nous avoir rejoint dans notre mission de créer un monde où personne n'a à craindre un diagnostic de cancer. Votre carte est en route!</h2> : null}
            {/* <img src="./assets/CIBC-Logo.png" alt="Thank you" /> */}
        </div>
    );
    }

export default Thankyou;