import { Button } from "@mui/material"
import React from "react"
import { db } from '../util/config'
import { set, ref } from 'firebase/database'
import { uid } from 'uid';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import emailjs from '@emailjs/browser';



import styles from '../styles/preview.module.css'

const Preview = ({isEnglish,  card, step, setStep, header, setHeader, userName, setName, usersEmail, setEmail, recipientName, setRecipientName, recipientEmail, setRecipientEmail, cardContent }) => {
    const [disable, setDisable] = React.useState(false);
    setHeader("Preview")

    const handleClick = async (event) => {
        const uuid = uid()
        set(ref(db, `users/${uuid}`), {
            cardContent,
            uuid,
            userName,
            usersEmail,
            recipientName,
            recipientEmail,
            card,
            Date: new Date().toUTCString()

        })



        try {
            const templateParams = {
                from_name: userName,
                from_email: usersEmail,
                to_email: recipientEmail,
                to_name: recipientName,
                uuid: uuid,
                subject: isEnglish ? "Your gift enclosed from Team CIBC and the CIBC foundation" : "Voici votre cadeau de l'Équipe CIBC et de la Fondation CIBC",
                email_content: isEnglish ? "https://firebasestorage.googleapis.com/v0/b/fuse-cibc-greetingcard.appspot.com/o/CIBC_Email.jpg?alt=media&token=aced591a-1d20-4562-96d7-b70c64c9bff2" : 'https://firebasestorage.googleapis.com/v0/b/fuse-cibc-greetingcard.appspot.com/o/FR_CIBC_Email.jpg?alt=media&token=139ac09a-eaef-461b-a145-bf20756e4272',
                unsubscribe: isEnglish ? "Unsubscribe" : "Se désabonner",
                privacy: isEnglish ? "Privacy Policy" : "Politique de confidentialité",
                privacy_url: isEnglish ? "https://www.cibc.com/en/privacy-security/privacy-policy.html" : "https://www.cibc.com/fr/privacy-security/privacy-policy.html",
            };

            console.log(templateParams.subject)

            // emailjs.send('service_ms0jxrr', 'template_uwbys5u', templateParams, 'kwJClUUI2CFdDBNa9')
            //     .then(function (response) {
            //         console.log('SUCCESS!', response.status, response.text);
            //         if (response.status === 200) {
            //             window.location.href = '/thankyou'
            //         }
            //     }, function (error) {
            //         console.log('FAILED...', error);
            //     });

            emailjs.send('service_ms0jxrr', 'template_zd067bm', templateParams, '030yn_eF_KUs0meiQ')
            .then(function (response) {
                console.log('SUCCESS!', response.status, response.text);
                if (response.status === 200) {
                    window.location.href = '/thankyou'
                }
            }, function (error) {
                console.log('FAILED...', error);
            });


        } catch (err) {
            console.log(err);
        }
        setDisable(true)
    }

    return (
        <div>
            <div className={styles.birthdayCard}>
                {isEnglish ? <span>
                    {card === 1 ? <div className={styles.cardFront}></div> : null}
                    {card === 2 ? <div className={styles.cardFront2}></div> : null}
                    {card === 3 ? <div className={styles.cardFront3}></div> : null} </span>
                : null }
                {!isEnglish ? <span>
                    {card === 1 ? <div className={styles.cardFrontFR}></div> : null}
                    {card === 2 ? <div className={styles.cardFront2FR}></div> : null}
                    {card === 3 ? <div className={styles.cardFront3FR}></div> : null} </span>
                : null }
                <div className={styles.cardInside}>
                    <div className={styles.wishes}>
                        <p>{recipientName},</p>
                        <p>{cardContent}</p>
                        <p className={styles.name}>-{userName}</p>
                    </div>
                </div>
            </div>
           {isEnglish ? <Button disabled={disable} sx={{ mt: 2, width: '90%' }} variant="contained" color="primary" className={styles.button} onClick={() => { handleClick() }}>Send</Button> : null}
           {!isEnglish ? <Button disabled={disable} sx={{ mt: 2, width: '90%' }} variant="contained" color="primary" className={styles.button} onClick={() => { handleClick() }}> Envoyer</Button> : null}
        </div>

    )
}

export default Preview