import React, { useEffect } from 'react'
import styles from '../styles/selectcard.module.css'
import { Button } from "@mui/material"
import CardSigning from './CardSigning'


const SelectCard = ({ isEnglish }) => {
    const [card, setCard] = React.useState(0)
    let [step, setStep] = React.useState(1)
    const [header, setHeader] = React.useState("")
    const [subHeader , setSubHeader] = React.useState("")

    

    let handleCard = (e) => {
        setCard(e)
        setStep(++step)

    }
    // console.log('Card: ' + card)
    // console.log('Step: ' + step)
    // console.log('Header: ' + header)
    


    const EnglishCard = () => {
        return (
            <div className={styles.wrapper}>
                <div className={styles.card_container}>
                    <div className={styles.envelope}>
                        <div className={styles.back}></div>
                        {isEnglish ? <div className={styles.letter}></div> : <div className={styles.letterFR}></div> }
                            <div className={styles.front}></div>
                        <div className={styles.top}></div>
                        {/* <div className={styles.shadow}></div> */}
                    </div>
                    <div className={styles.card_selection}>
                        {isEnglish ? <Button variant="contained" className={styles.card_selection_button} onClick={()=>handleCard(1)}>Select</Button> : null }
                        {!isEnglish ? <Button variant="contained" className={styles.card_selection_button} onClick={()=>handleCard(1)}>Sélectionner</Button> : null }
                    </div>
                    <div className={styles.envelope}>
                        <div className={styles.back}></div>
                        {isEnglish ? <div className={styles.letter2}></div> : <div className={styles.letter2FR}></div> }

                        <div className={styles.front}></div>
                        <div className={styles.top}></div>
                    </div>
                    <div className={styles.card_selection}>
                        {isEnglish ? <Button variant="contained" className={styles.card_selection_button} onClick={()=>handleCard(2)}>Select</Button> : null }
                        {!isEnglish ? <Button variant="contained" className={styles.card_selection_button} onClick={()=>handleCard(2)}>Sélectionner</Button> : null }
                    </div>                
                    <div className={styles.envelope}>
                        <div className={styles.back}></div>
                        {isEnglish ? <div className={styles.letter3}></div> : <div className={styles.letter3FR}></div> }

                        <div className={styles.front}></div>
                        <div className={styles.top}></div>
                    </div>
                    <div className={styles.card_selection}>
                        {isEnglish ? <Button variant="contained" className={styles.card_selection_button} onClick={()=>handleCard(3)}>Select</Button> : null }
                        {!isEnglish ? <Button variant="contained" className={styles.card_selection_button} onClick={()=>handleCard(3)}>Sélectionner</Button> : null }
                    </div>
            

                </div>
            </div>
        )
    }


   

    let englishHeader = 'Choose your e-card'
    let englishSubHeader = 'For every card sent, we will donate $5* to the Canadian Cancer Society on your behalf.'

    let frenchHeader = ' Choisissez votre carte virtuelle'
    let frenchSubHeader = 'Pour chaque carte envoyée, un don de 5 $* sera versé à la Société canadienne du cancer'

    // set header based on language
 useEffect(() => {
        if (isEnglish) {
            setHeader(englishHeader)
        } else {
            setHeader(frenchHeader)
        }
    }, [isEnglish])


    const EnglishHeader = () => {
        return (
            <>
                <p className={styles.steps}>Step {step} / 4</p>
                <h1 className={styles.heading}>{header}</h1>
                {step === 1 ? <p className={styles.subheading}>{englishSubHeader}</p> : null}
                {/* <p className={styles.subheading}>{englishSubHeader}</p> */}
            </>
        )
    }

    const FrenchHeader = () => {
        return (
            <>
                {isEnglish ? <p className={styles.steps}>Step {step} / 4</p> : <p className={styles.steps}>Marcher {step} / 4</p>}
                <h1 className={styles.heading}>{header}</h1>
                {step === 1 ? <p className={styles.subheading}>{frenchSubHeader}</p> : null}
                {/* <p className={styles.subheading}>{frenchSubHeader}</p> */}
            </>
        )
    }




    return (
        <>
            {isEnglish === true 
            ? <EnglishHeader />
            : <FrenchHeader />}
            
            {card < 1 ? <EnglishCard /> : <CardSigning {...{card, isEnglish, step, setStep, header, setHeader, subHeader, setSubHeader}}/>}
            {/* <EnglishCard /> */}
        </>
    )
}

export default SelectCard