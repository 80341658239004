import { createTheme } from "@mui/material/styles";

const Colors = {
    primary: "#c41f3e",
    secondary: "#F5F5F5",
    succsess: "#00BFA6",
    error: "#FF0000",
    warning: "#FFC107",
    info: "#2196F3",
};

const theme = createTheme({
    palette: {
        primary: {
            main: Colors.primary,
        },
        secondary: {
            main: Colors.secondary,
        }
    },

});

export default theme;

