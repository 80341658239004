import React from "react";
import { TextField, Button } from "@mui/material";
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import styles from '../styles/userinformation.module.css'

const UserInformation = ({ step, setStep, header, setHeader, subHeader, setSubHeader, isEnglish, userName, setName, usersEmail, setEmail, recipientName, setRecipientName, recipientEmail, setRecipientEmail }) => {
  console.log("Name: " + userName);
  console.log("Email: " + usersEmail);
  console.log("Recipient Name: " + recipientName);
  console.log("Recipient Email: " + recipientEmail);

  
  const EnglishUserInformation = () => {
    setHeader("Please fill out the information")
    // Form information and Handling
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors }
    } = useForm();

    // Submit to Database
    const onSubmit = (data) => {
      console.log(data);
      setName(data.usersName);
      setEmail(data.usersEmail);
      setRecipientName(data.recipientName);
      setRecipientEmail(data.recipientEmail);
      setStep(++step);
    }; // your form submit function which will invoke after successful validation

    console.log(watch("example")); // you can watch individual input by pass the name of the input
    setSubHeader("")


    return (

      <div className={styles.wrapper}>
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField autoFocus required id="outlined-basic" variant="outlined" label="Your Name" sx={{ mt: 2, width: '90%' }}   {...register("usersName", { required: true })} />

          <TextField required id="outlined-basic" variant="outlined" sx={{ mt: 2, width: '90%' }} type='email' label="Your Email" {...register("usersEmail", { required: true })} />

          <TextField required id="outlined-basic" variant="outlined" label="Recipients Name" sx={{ mt: 2, width: '90%' }}   {...register("recipientName", { required: true })} />

          <TextField required id="outlined-basic" variant="outlined" sx={{ mt: 2, width: '90%' }} type='email' label="Recipients Email" {...register("recipientEmail", { required: true })} />
          {/* errors will return when field validation fails  */}
          {errors.exampleRequired && <p>This field is required</p>}

          <div>

            <Button variant="contained" sx={{ mt: 2 }} type="submit" className={styles.nextButton}>Next</Button>
          </div>
        </form>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    </div>
    )
  }




  const FrenchUserInformation = () => {
    setHeader("Veuillez remplir les informations")
    setSubHeader(" ")

    // Form information and Handling
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors }
    } = useForm();

    // Submit to Database
    const onSubmit = (data) => {
      console.log(data);
      setName(data.usersName);
      setEmail(data.usersEmail);
      setRecipientName(data.recipientName);
      setRecipientEmail(data.recipientEmail);
      setStep(++step);
    }; // your form submit function which will invoke after successful validation

    console.log(watch("example")); // you can watch individual input by pass the name of the input


    return (
      <div className={styles.container}>
        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
          <TextField autoFocus required id="outlined-basic" variant="outlined" label="Votre Nom" sx={{ mt: 2, width: '90%' }}   {...register("usersName", { required: true })} />

          <TextField autoFocus required id="outlined-basic" variant="outlined" sx={{ mt: 2, width: '90%' }} type='email' label="Votre E-mail" {...register("usersEmail", { required: true })} />

          <TextField autoFocus required id="outlined-basic" variant="outlined" label="Nom du destinataire" sx={{ mt: 2, width: '90%' }}   {...register("recipientName", { required: true })} />

          <TextField autoFocus required id="outlined-basic" variant="outlined" sx={{ mt: 2, width: '90%' }} type='email' label="E-mail des destinataires" {...register("recipientEmail", { required: true })} />
          {/* errors will return when field validation fails  */}
          {errors.exampleRequired && <p>This field is required</p>}

          <div>

            <Button variant="contained" sx={{ mt: 2 }} type="submit" className={styles.nextButton}>SUIVANT</Button>
          </div>
        </form>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </div>
    )
  }

  return (
    <>
      {isEnglish ? <EnglishUserInformation /> : null}
      {!isEnglish ? <FrenchUserInformation /> : null}


    </>
  )
}

export default UserInformation