import {initializeApp} from 'firebase/app';
import {getDatabase} from 'firebase/database';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCaM-iVhl934w6VpxDSIKsfAsv6D-cWu2E",
    authDomain: "fuse-cibc-greetingcard.firebaseapp.com",
    databaseURL: "https://fuse-cibc-greetingcard-default-rtdb.firebaseio.com",
    projectId: "fuse-cibc-greetingcard",
    storageBucket: "fuse-cibc-greetingcard.appspot.com",
    messagingSenderId: "634679709421",
    appId: "1:634679709421:web:873242c7d432a2987ac6e4",
    measurementId: "G-PWKSSRCLJD"
  };

const app = initializeApp(firebaseConfig);
export const db = getDatabase(app);