import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import ReactDOM from "react-dom";
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import styles from '../styles/modal.module.css'
import { db } from '../util/config'
import {set, ref} from 'firebase/database'
import { uid } from 'uid';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  fontFamily: 'Whitney-Book',
};

export default function BasicModal({ isEnglish, header, open, setOpen, step, setStep, cardContent, setCardContent}) {
//   const [open, setOpen] = React.useState(false);
//   const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    
    const {
      register,
      handleSubmit,
      watch,
      formState: { errors }
    } = useForm();
    
    const onSubmit = (data) => {
      // setStep(e)
      console.log(data);
      setCardContent(data.cardContent)
      setStep(++step)
    }; // your form submit function which will invoke after successful validation
  
    console.log(watch("cardContent")); // you can watch individual input by pass the name of the input
  




  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className={styles.modal}>
          {/* <Typography id="modal-modal-title" variant="h6" component="h2">
            {header}
          </Typography> */}

          <form onSubmit={handleSubmit(onSubmit)}>
              {/* include validation with required or other standard HTML validation rules */}
              {isEnglish ? <TextField required id="outlined-multiline-static" multiline rows={4} sx={{ mt: 2,  width: 1, borderColor: '#c41f3e' }} className={styles.textfield} label="Fill out the cards Contents" {...register("cardContent", { required: true })} /> : null }
              {!isEnglish ? <TextField required id="outlined-multiline-static" multiline rows={4} sx={{ mt: 2,  width: 1, borderColor: '#c41f3e' }} className={styles.textfield} label=" Veuillez remplir la carte" {...register("cardContent", { required: true })} /> : null }
              {/* errors will return when field validation fails  */}
              {errors.exampleRequired && <p>This field is required</p>}
              <div>
              {isEnglish ? <Button variant="contained" sx={{ mt: 2, width: 1, bgcolor: '#c41f3e'}} type="submit">Next</Button> : null }
              {!isEnglish ? <Button variant="contained" sx={{ mt: 2, width: 1, bgcolor: '#c41f3e'}} type="submit">Suivant</Button> : null }
                </div>
            </form>

        </Box>
      </Modal>
    </div>
  );
}
