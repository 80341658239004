    import { Button } from "@mui/material"
    import useLocalStorage from '../util/store'
    import styles from '../styles/language.module.css'

    const LanguageButtons = ({ setEnglish, setClicked }) => {

        const handleClick = (props) => {
            setEnglish(props)
            setClicked(true)
        }

        return (

            <>
                <Button variant="contained" className={styles.languageButton} onClick={()=>handleClick(true)}>ENGLISH</Button>
                <Button variant="contained" className={styles.languageButton} onClick={()=>handleClick(false)}>FRANCAIS</Button>
            </>
            )
    };

    export default LanguageButtons