import * as React from 'react';
import CTA_Image from '../assets/cibc-event.jpeg'
import styles from '../styles/language.module.css'
import useLocalStorage from '../util/store'
import LanguageButtons from './LanguageButtons'


export default function CTA({isEnglish, setEnglish, setClicked}) {
    // const [isEnglish, setEnglish] = useLocalStorage('isEnglish', true);
    return (
        <>
            {/* <img src={CTA_Image} className={styles.ctaImage} alt='' /> */}

                <p className={styles.ctaBody}>
                    Welcome 
                    <br/>
                    <br/>
                    Bienvenue
                </p>
            {/* {isEnglish === true ? 
            :   <p className={styles.ctaBody}>
                </p>
            } */}

            <LanguageButtons {...{ setEnglish, setClicked }}/>
        </>
    );
}