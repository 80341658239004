import React from 'react';
import './App.css';
import Language from './util/Language';
import Thankyou from './components/Thankyou';
import Recipient from './components/recipient/RecipientCard';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useLocalStorage from './util/store';
// import Plausible from 'plausible-tracker'

// const plausible = Plausible({
//   domain: 'wererunningforyou.ca',
// })

function App() {
  const [isEnglish, setEnglish] = useLocalStorage('isEnglish', true);
  return (
    <div className="App">
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Language {...{isEnglish, setEnglish}}/>} />
          <Route path="/recipient/:id" element={<Recipient {...{isEnglish, setEnglish}}/>} />
          <Route path="/thankyou" element={<Thankyou {...{isEnglish, setEnglish}} />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
