import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import styles from '../styles/header.module.css'

import CIBC from '../assets/EN_Primary-Stacked_Logo_CMYK.svg'
import CIBC_FRENCH from '../assets/FR_Primary-Stacked_Logo_CMYK@2x.png'

export default function Header({isEnglish}) {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar className={styles.appbar} position="static">
        <Toolbar>
         
            {isEnglish ? <img src={CIBC} className={styles.logo} alt="" /> : null}
            {!isEnglish ? <img src={CIBC_FRENCH} className={styles.logo} alt="" /> : null}
          
        </Toolbar>
      </AppBar>
    </Box>
  );
}
