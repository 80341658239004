import React, { useEffect } from "react"
import { db } from '../../util/config'
import {set, ref, onValue} from 'firebase/database'
import { uid } from 'uid';
import CIBC from '../../assets/EN_Primary-Stacked_Logo_CMYK.svg'
import CIBC_FR from '../../assets/FR_Primary-Stacked_Logo_CMYK.svg'


import styles from '../../styles/preview.module.css'
import {useParams} from 'react-router-dom'



const RecipientCard = ({isEnglish}) => {
    const [recipientName, setRecipientName] = React.useState("");
    const [recipientEmail, setRecipientEmail] = React.useState("");
    const [cardContent, setCardContent] = React.useState("");
    const [card, setCard] = React.useState("");
    const [userName, setUserName] = React.useState("");

    const params = useParams();

    const map1 = new Map();

    useEffect(() => {
        onValue(ref(db, 'users'), (snapshot) => {
            let data = snapshot.val();
            // console.log(data);
            map1.set(params.id, data);

            Object.keys(data).forEach((key) => {
                // console.log (key);
                if (key === params.id) {
                    // console.log(data[key]);
                    setRecipientName(data[key].recipientName);
                    setRecipientEmail(data[key].email);
                    setCardContent(data[key].cardContent);
                    setCard(data[key].card);
                    setUserName(data[key].userName);
                    
                }
            })
            

      });
    }, [])


    console.log(params.id);

    return (
        <div className={styles.recipientCardContainer}>
            {isEnglish ? <img src={CIBC} alt="CIBC" className={styles.cibcLogo} /> : <img src={CIBC_FR} alt="CIBC" className={styles.cibcLogo} />}
            
            <div className={styles.birthdayCard}>
            {isEnglish ? <span>
                    {card === 1 ? <div className={styles.cardFront}></div> : null}
                    {card === 2 ? <div className={styles.cardFront2}></div> : null}
                    {card === 3 ? <div className={styles.cardFront3}></div> : null} </span>
                : null }
                {!isEnglish ? <span>
                    {card === 1 ? <div className={styles.cardFrontFR}></div> : null}
                    {card === 2 ? <div className={styles.cardFront2FR}></div> : null}
                    {card === 3 ? <div className={styles.cardFront3FR}></div> : null} </span>
                : null }
                <div className={styles.cardInside}>
                    <div className={styles.wishes}>
                        <p>{recipientName},</p>
                        <p>{cardContent}</p>
                        <p className={styles.name}>-{userName}</p>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default RecipientCard