import * as React from 'react';
import CTA_Image from '../assets/cibc-event.jpeg'
import CTA from '../components/CTA';
import styles from '../styles/language.module.css'
import useLocalStorage from '../util/store'
import LegalFooter from '../components/LegalFooter';
import Header from '../components/Header';
import SelectCard from '../components/SelectCard';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../styles/Styles';
import { Link, Route } from 'react-router-dom';
import Thankyou from '../components/Thankyou';

export default function Language({isEnglish, setEnglish}) {
    const [clicked, setClicked] = React.useState(false);
    console.log(clicked)

    return (
        <ThemeProvider theme={theme}>
            <Header {...{isEnglish}}/>
            
            {
                clicked === false ? <CTA {...{isEnglish, setEnglish, setClicked}}/> : <SelectCard {...{isEnglish}}/>
            }
            
            <Link to="/thankyou" element={<Thankyou {...{isEnglish}}/>} />
            <LegalFooter {...{isEnglish}}/>
        </ThemeProvider>
        
    );
}