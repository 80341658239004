import React from "react"
import styles from "../styles/cardsigning.module.css"
import Modal from '../util/Modal'
import Preview from "./Preview"
import UserInformation from './UserInformation'
import {db} from '../util/config'
import {set, ref} from 'firebase/database'
import {uid} from 'uid'


const CardSigning = ({card, isEnglish, step, setStep, header, setHeader, subHeader, setSubHeader }) => {
    const [userName, setName] = React.useState("")
    const [usersEmail, setEmail] = React.useState("")
    const [recipientEmail, setRecipientEmail] = React.useState("")
    const [recipientName, setRecipientName] = React.useState("")
    const [cardContent, setCardContent] = React.useState("")

    // const uuid = uid()
    // set(ref(db, `users/${uuid}`), {
    //   cardContent,
    //   uuid
    // }) 

    
    const EnglishSigning = () => {
        const [open, setOpen] = React.useState(false)

        setHeader("Please fill out the card")
        
        const handleOpen = (e) => {
            setOpen(e)
        }


        return (
        <div className={styles.container}>
        <Modal {...{ isEnglish, header, open, setOpen, cardContent, setCardContent, step, setStep}}/>



            <div className={styles.wrapper}>
                <div className={styles.card_container} onClick={()=>handleOpen(true)}>
                    <div className={styles.envelope}>
                        <div className={styles.back}></div>
                        {card === 1 ? <div className={styles.letter}></div> : null}
                        {card === 2 ? <div className={styles.letter2}></div> : null}
                        {card === 3 ? <div className={styles.letter3}></div> : null}
                            {/* <div className={styles.text}>{cardContent}</div>
                        </div> */}
                        <div className={styles.front}></div>
                        <div className={styles.top}></div>
                    </div>
                </div>
            </div>
        </div>    
        )
    }

    const FrenchSigning = () => {
        const [open, setOpen] = React.useState(false)

        setHeader("Remplissez la carte")
        const handleOpen = (e) => {
            setOpen(e)
        }


        return (
        <div className={styles.container}>
        <Modal {...{ isEnglish, header, open, setOpen, cardContent, setCardContent, step, setStep}}/>



            <div className={styles.wrapper}>
                <div className={styles.card_container} onClick={()=>handleOpen(true)}>
                    <div className={styles.envelope}>
                        <div className={styles.back}></div>
                        {/* <div className={styles.letter}> */}
                            {card === 1 ? <div className={styles.letterFR}></div> : null}
                            {card === 2 ? <div className={styles.letter2FR}></div> : null}
                            {card === 3 ? <div className={styles.letter3FR}></div> : null}
                            {/* <div className={styles.text}>{cardContent}</div> */}
                        {/* </div> */}
                        <div className={styles.front}></div>
                        <div className={styles.top}></div>
                    </div>
                </div>
            </div>
        </div>    
        )
    }

    return (
       <>
            {step === 2 && isEnglish ? <EnglishSigning /> : null}
            {step === 2 && !isEnglish ? <FrenchSigning /> : null}
            {step === 3 ? <UserInformation {...{step, setStep, header, setHeader, subHeader, setSubHeader, isEnglish, userName, setName, usersEmail, setEmail, recipientName, setRecipientName, recipientEmail, setRecipientEmail}}/> : null}
            {step === 4 ? <Preview {...{isEnglish, card, setHeader, userName, cardContent, recipientName, usersEmail, recipientEmail }}  /> : null}
       </>

    )
}

export default CardSigning

